<template>
	<v-menu v-model="showing" :close-on-click="false" :close-on-content-click="false" :max-width="maxWidth" left
			offset-y>

		<template v-slot:activator="{ on, attrs }">
			<v-btn :class="rightMargin ? 'mr-2' : ''" v-bind="attrs" v-on="on" color="primary">
				<v-fade-transition mode="out-in">
					<v-icon v-if="!showing && hasActiveFilters">mdi-filter</v-icon>
					<v-icon v-else>mdi-filter-outline</v-icon>
				</v-fade-transition>
			</v-btn>
		</template>

		<v-card outlined>
			<v-container fluid>
				<v-row dense>
					<v-col cols="12">
						<v-select v-model="filters.limit" :items="limitOptions" dense hide-details label="Limit"
								  outlined></v-select>
					</v-col>

					<v-col v-for="(filter, idx) in filterTypes" :key="idx" cols="12">
						<template v-if="filter.type === 'text'">
							<v-text-field v-model="filters[filter.name]" :id="newUUID()" :label="filter.label" clearable
										  dense hide-details outlined @keydown.enter="applyFilters"></v-text-field>
						</template>

						<template v-else-if="filter.type === 'number'">
							<v-text-field :id="newUUID()" :label="filter.label" v-model.number="filters[filter.name]"
										  clearable dense hide-details outlined type="number"
										  @keydown.enter="applyFilters"></v-text-field>
						</template>

						<template v-else-if="filter.type === 'select'">
							<v-select v-model="filters[filter.name]" :label="filter.label" :items="filter.items"
									  :item-text="filter.itemText || 'text'" :item-value="filter.itemValue || 'value'"
									  dense hide-details outlined @keydown.enter="applyFilters"></v-select>
						</template>

						<template v-else-if="filter.type === 'toggle'">
							<v-switch v-model="filters[filter.name]" :label="filter.label" dense hide-details
									  inset></v-switch>
						</template>
					</v-col>

					<v-col v-if="orderOptions.length > 0" cols="12">
						<v-row class="align-center" dense>
							<v-col>
								<v-select v-model="filters.order" :items="orderOptions" dense hide-details
										  label="Order By" outlined></v-select>
							</v-col>
							<v-col cols="auto">
								<v-btn-toggle v-model="filters.sort" dense mandatory>
									<v-btn value="asc">
										<v-icon>mdi-sort-alphabetical-ascending</v-icon>
									</v-btn>
									<v-btn value="desc">
										<v-icon>mdi-sort-alphabetical-descending</v-icon>
									</v-btn>
								</v-btn-toggle>
							</v-col>
						</v-row>
					</v-col>

				</v-row>

			</v-container>

			<v-card-actions>
				<v-btn @click="cancelFilters" text>
					Cancel
				</v-btn>
				<v-spacer></v-spacer>
				<v-btn @click="applyFilters" color="primary" text>
					Apply
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-menu>
</template>

<script>

export default {
	name: 'TableFilters',
	props: {
		value: {type: Object, required: true},
		filterTypes: {type: Array, required: true},
		orderOptions: {type: Array, default: () => []},
		limitOptions: {type: Array, default: () => [10, 20, 30]},
		maxWidth: {type: String, default: '350'},
		rightMargin: {type: Boolean, default: false},
	},
	data: () => ({
		showing: false,
		filters: {},
	}),
	created() {
		this.filters = {...this.value}
	},
	methods: {
		applyFilters() {
			this.filters.page = 1
			this.$emit('input', {...this.filters})
			this.showing = false
		},
		cancelFilters() {
			this.filters = {...this.value}
			this.showing = false
		}
	},
	computed: {
		hasActiveFilters() {
			for (let i = 0; i < this.filterTypes.length; i++) {
				if (this.filters[this.filterTypes[i].name] !== null && !this.filterTypes[i].required) return true
			}

			return false
		}
	}
}
</script>

<style scoped>

</style>
